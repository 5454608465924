/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Report from './Report v4.pdf';
import Presentation from './DIP Individual Presentation - Haoran.pdf';
import ResultImage from './result.png';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    br: "br",
    em: "em",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Automatic Review System based on Deep Neural network"), "\n", React.createElement(_components.p, null, "This project is to create an automatic review system over user taken images. My group is working on the simplest encoder-decoder model,\nwhere the encoder is achieved by Convolutional Neural Network (CNN) and decoder is achieved by Recurrent Neural Networks (RNN).", React.createElement(_components.br), "\n", "The project includes both ", React.createElement(_components.em, null, "computer vision"), " (CV) and ", React.createElement(_components.em, null, "natual language processing"), " (NLP) disciplines."), "\n", React.createElement(_components.h2, null, "Key Concepts"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Convolutional_neural_network"
  }, "Convolutional Neural Network (CNN)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://keras.io/api/applications/inceptionv3/"
  }, "InceptionV3 Feature")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Recurrent_neural_network"
  }, "Recurrent Neural Networks (RNN)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Long_short-term_memory"
  }, "Long short-term memory (LSTM)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nlp.stanford.edu/projects/glove/"
  }, "GloVe Embeddings")), "\n"), "\n", React.createElement(_components.h2, null, "Key Technology Takeaways"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Python"), "\n", React.createElement(_components.li, null, "Pandas, Numpy"), "\n", React.createElement(_components.li, null, "PyTorch, Keras"), "\n", React.createElement(_components.li, null, "Use of GPU"), "\n", React.createElement(_components.li, null, "Linux System"), "\n"), "\n", React.createElement(_components.h2, null, "Contribution"), "\n", React.createElement(_components.p, null, "The project was completed by a group of 7 members, who were divided into 3 teams and took charge of training different models to compare.Team A, where I was leading 3 members, worked on an encoder-decoder architecture model without attention mechanism.\nI was responsible for most of data processing, programming and model tuning tasks for my team."), "\n", React.createElement(_components.h4, null, "Individual Presentation Slides"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: Presentation,
    download: true
  }, React.createElement("i", {
    class: "fa-solid fa-person-chalkboard fa-2x"
  })))), "\n", React.createElement(_components.h2, null, "Result Example"), "\n", React.createElement("img", {
    src: ResultImage,
    alt: "result image",
    style: {
      maxWidth: "max(350px, 50vw)",
      paddingTop: "5px",
      paddingBottom: "10px"
    }
  }), "\n", React.createElement(_components.h2, null, "Detailed Report"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: Report,
    download: true
  }, React.createElement("i", {
    class: "fa-solid fa-file-arrow-down fa-2x"
  })))), "\n", React.createElement(_components.h2, null, "Source Code"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: "https://github.com/Haoran101/DIP"
  }, React.createElement("i", {
    class: "fa-brands fa-github fa-2x"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
